<template>
    <div class="yj-conten" style="margin-top:12px;height: calc(100% - 36px);background-color:#fff;">
        <div style="margin-top:18px;">
            <a-form-model ref="ruleForm" :model="FormModel" :rules="rules" v-bind="layout">
                <a-form-model-item :wrapper-col="{ span: 6, offset: 2 }" v-if="MessageShow.Show">
                    <a-alert  :message="MessageShow.Text" :type="MessageShow.Type" show-icon />
                </a-form-model-item>
                <a-form-model-item label="原密码" prop="OldPassword" hasFeedback>
                    <a-input placeholder="请输入原密码" v-model="FormModel.OldPassword" type="password" autocomplete="off" />

                </a-form-model-item>
                <a-form-model-item label="新密码" prop="NewPassword" hasFeedback>
                    <a-input placeholder="请输入新密码" v-model="FormModel.NewPassword" type="password" autocomplete="off" />

                </a-form-model-item>
                <a-form-model-item label="重复新密码" prop="CheckPassword" hasFeedback>
                    <a-input placeholder="请重新输入新密码" v-model="FormModel.CheckPassword" type="password" autocomplete="off" />

                </a-form-model-item>

                <a-form-model-item :wrapper-col="{ span: 2, offset: 4 }">
                    <a-button type="primary" @click="submitForm('ruleForm')">保存</a-button>
                </a-form-model-item>
            </a-form-model>

        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "EditPassword",
        data() {
            return {
                MessageShow: {
                    Text: "",
                    Show: false,
                    Type: "error"
                },
                FormModel: {
                    OldPassword: '',
                    NewPassword: '',
                    CheckPassword: ''
                },
                rules: {
                    OldPassword: [
                        { validator: this.InputChange },
                        { message: "请输入原密码", required: true },
                        { message: "最长限制16个字符", max: 16 },
                        { message: "最短限制6个字符", min: 6 }
                    ],
                    NewPassword: [
                        { validator: this.InputChange },
                        { message: "请输入新密码", required: true },
                        { message: "最长限制16个字符", max: 16 },
                        { message: "最短限制6个字符", min: 6 },
                        { validator: this.validateNewPassword }
                    ],
                    CheckPassword: [
                        { validator: this.InputChange },
                        { message: "请重新输入新密码", required: true },
                        { message: "最长限制16个字符", max: 16 },
                        { message: "最短限制6个字符", min: 6 },
                        { validator: this.validateCheckPassword }
                    ]
                },
                layout: {
                    labelCol: { span: 2 },
                    wrapperCol: { span: 6 },
                },
            };
        },
        methods: {
            InputChange: function (rule, value, callback) {
                this.MessageShow.Show = false;
                callback();
            },
            submitForm(formName) {
                var self = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var op = {
                            url: "/SystemSetting/EidtPassword",
                            data: self.FormModel,
                            OnSuccess: function () {
                                self.MessageShow.Text = "修改成功";
                                self.MessageShow.Show = true;
                                self.MessageShow.Type = "success";
                            },
                            ErrorMessageShow: function (msg) {
                                self.MessageShow.Text = msg;
                                self.MessageShow.Show = true;
                                self.MessageShow.Type = "error";
                            }
                        };
                        http.Post(op);
                    } else {
                        return false;
                    }
                });
            },
            validateNewPassword: function (rule, value, callback) {
                if (this.FormModel.OldPassword == this.FormModel.NewPassword) {
                    callback(new Error('新密码不能与原密码相同'));
                } else {
                    callback();
                }
            },
            validateCheckPassword: function (rule, value, callback) {
                if (this.FormModel.CheckPassword != this.FormModel.NewPassword) {
                    callback(new Error('两次输入的密码不一致'));
                } else {
                    callback();
                }
            }
        },
        mounted: function () { },
        computed: {},
        components: {
        }
    };
</script>